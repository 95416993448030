<template>
    <div>
        <list-item style="padding:0" :clickable="false">
            <div
                class="l-stack l-spread l-full-width l-gap-1 timeline timeline-pause"
            >
                <p class="t-subtle t-small">
                    {{ $t('pause') }}
                    <span class="" style="font-weight: bold">{{
                        pauseDuration
                    }}</span>
                </p>
            </div>
        </list-item>
    </div>
</template>

<script>
import ListItem from '@/components/ListItem'
import moment from 'moment-timezone'

export default {
    components: {
        ListItem,
    },
    props: {
        tripFrom: {
            type: Object,
            required: true,
        },
        tripTo: {
            type: Object,
            required: true,
        },
    },
    computed: {
        pauseDuration() {
            let timeDiff = moment(this.tripTo.first_measurement.timestamp).diff(
                moment(this.tripFrom.last_measurement.timestamp)
            )
            let tripDuration = moment.duration(timeDiff)
            return tripDuration.format('h[h] mm[min] ss[s]', {
                usePlural: false,
            })
        },
    },
    methods: {
        formatTimeWithTime(time) {
            return moment(time).format('DD.MM.YY HH:mm')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "pause": "Break"
    },
    "de": {
        "pause": "Pause"
    },
    "it": {
        "pause": "Rottura"
    }
}
</i18n>

<style lang="scss" scoped>
.timeline {
    position: relative;
    border-left: 2px solid black;
    margin: 0 16px;
    padding: 16px;
}

.timeline-pause {
    border-left-style: dashed;
    padding: 32px 16px;
}

.timeline-icon {
    position: absolute;
    left: 8px;
}

.timline-bubble {
    --bubble_size: 16px;
    position: absolute;
    left: calc(((var(--bubble_size) / 2) + 1px) * -1);
    background-color: black;
    border-radius: 50%;
    width: var(--bubble_size);
    height: var(--bubble_size);
}
</style>
