<template>
    <div class="l-stack">
        <div class="l-stack u-bb">
            <hidden-form
                class="u-bb"
                :hidden="isCustomFormHidden"
                @toggle="isCustomFormHidden = $event"
            >
                <template #title>
                    {{ $t('customFormTitle') }}
                </template>

                <template #form>
                    <div class="l-stack l-gap-1 l-padded">
                        <date-range-input
                            :value="dateRange"
                            @input="handleDateRangeChange"
                        />
                    </div>
                </template>
            </hidden-form>

            <hidden-form
                :hidden="isPredefinedFormHidden"
                @toggle="isPredefinedFormHidden = $event"
            >
                <template #title>
                    {{ $t('predefinedFormTitle') }}
                </template>

                <template #form>
                    <div class="l-stack l-gap-1 l-padded">
                        <ax-button
                            v-for="(option, key, index) in dateOptions"
                            :key="index"
                            @click="handleDateRangeChange(option)"
                        >
                            {{ $t(key) }}
                        </ax-button>
                    </div>
                </template>
            </hidden-form>
        </div>

        <div
            v-if="isTripModePossible"
            class="l-padded l-inline l-spread l-center-v u-bb"
        >
            <span>{{ $t('showTrips') }}</span>

            <toggle-button
                :value="isTripMode"
                sync
                color="#02a5e1"
                :width="40"
                :height="24"
                :speed="150"
                @input="
                    $router.push({
                        name: isTripMode ? 'locationhistory' : 'triphistory',
                        params: { id, isTripModePossible },
                    })
                "
            />
        </div>

        <div
            v-if="isTripMode"
            class="l-padded l-inline l-spread l-center-v u-bb"
        >
            <span>{{ $t('export') }}</span>

            <icon-button @click="handleDownload">
                <file-download-icon width="18" height="18" />
            </icon-button>
        </div>

        <List
            v-infinite-scroll="loadMoreListData"
            :is-loading="isTripMode ? isLoading : isLoadingHistory"
            infinite-scroll-distance="900"
        >
            <template v-if="isTripMode">
                <div v-for="(trip, index) in listDataPortion" :key="trip.id">
                    <trip-list-item
                        :trip="trip"
                        :selected="
                            getSelectedTrip && getSelectedTrip.id === trip.id
                        "
                        :class="'historyItem-' + trip.id"
                        @click="handleListItemClick"
                    />

                    <trip-pause-list-item
                        v-if="listDataPortion[index + 1]"
                        :trip-from="trip"
                        :trip-to="listDataPortion[index + 1]"
                    />
                </div>
            </template>

            <template v-else>
                <asset-location-history-item
                    v-for="location in listDataPortion"
                    :key="location.id"
                    :location="location"
                    :selected="selectedHistoryLocationID == location.id"
                    :class="'historyItem-' + location.id"
                    @click="handleListItemClick"
                />
            </template>
        </List>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import moment from 'moment-timezone'
import infiniteScroll from 'vue-infinite-scroll'
import { ToggleButton } from 'vue-js-toggle-button'

import { domHelper } from '@/utils'
import InfinityScrollHelper from '@/mixins/InfinityScrollHelper'

import AssetLocationHistoryItem from '@/components/AssetLocationHistoryItem'
import AxButton from '@/components/AxButton'
import DateRangeInput from '@/components/DateRangeInput'
import FileDownloadIcon from '@/components/icons/FileDownloadIcon'
import HiddenForm from '@/components/HiddenForm'
import IconButton from '@/components/IconButton'
import List from '@/components/List'
import TripListItem from '@/components/TripListItem'
import TripPauseListItem from '@/components/TripPauseListItem'

export default {
    name: 'AssetLocationHistory',
    components: {
        AssetLocationHistoryItem,
        AxButton,
        DateRangeInput,
        FileDownloadIcon,
        HiddenForm,
        IconButton,
        List,
        ToggleButton,
        TripListItem,
        TripPauseListItem,
    },
    directives: {
        infiniteScroll,
    },
    mixins: [InfinityScrollHelper],
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
        isTripModePossible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            dateOptions: {
                today: {
                    startDate: moment().startOf('day'),
                    endDate: moment().endOf('day'),
                },
                yesterday: {
                    startDate: moment()
                        .subtract(1, 'days')
                        .startOf('day'),
                    endDate: moment()
                        .subtract(1, 'days')
                        .endOf('day'),
                },
                thisMonth: {
                    startDate: moment().startOf('month'),
                    endDate: moment().endOf('month'),
                },
                lastMonth: {
                    startDate: moment()
                        .subtract(1, 'month')
                        .startOf('month'),
                    endDate: moment()
                        .subtract(1, 'month')
                        .endOf('month'),
                },
            },
            dateRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            isCustomFormHidden: false,
            isLoadingHistory: false,
            isPredefinedFormHidden: true,
        }
    },
    computed: {
        ...mapState('tracker', ['trackers']),
        ...mapGetters('tracker', [
            'assetHistoryData',
            'selectedHistoryLocationID',
        ]),
        ...mapGetters('trips', ['getSelectedTrip', 'getTrips', 'isLoading']),
        assetId() {
            return this.trackers.find(tracker => tracker.id == this.id)?.asset
        },
        isTripMode() {
            return this.$route.name === 'triphistory'
        },
        listData() {
            return this.isTripMode ? this.getTrips : this.assetHistoryData
        },
    },
    watch: {
        dateRange() {
            this.load()
        },
        getSelectedTrip() {
            if (this.getSelectedTrip) {
                this.scrollToHistoryItem(this.getSelectedTrip.id)
            }
        },
        isTripMode() {
            this.load()
        },
        selectedHistoryLocationID() {
            this.scrollToHistoryItem(this.selectedHistoryLocationID)
        },
    },
    beforeRouteLeave(to, from, next) {
        this.resetHistoryData()
        next()
    },
    methods: {
        ...mapActions('tracker', ['loadHistoryData']),
        ...mapActions('trips', ['loadTrips']),
        ...mapMutations('tracker', [
            'addHistoryData',
            'setSelectedHistoryLocationID',
            'resetHistoryData',
        ]),
        ...mapMutations('trips', ['setSelectedTripID']),
        handleDateRangeChange(dates) {
            this.dateRange = {
                startDate: moment(dates.startDate).toDate(),
                endDate: moment(dates.endDate).toDate(),
            }
        },
        handleDownload() {
            const rows = [
                [
                    'datetime',
                    'address_start',
                    'address_end',
                    'duration (s)',
                    'distance',
                    'driver_name',
                    'driver_id',
                    'lat_start',
                    'lng_start',
                    'lat_end',
                    'lng_end',
                ],
                ...this.listData.map(data => [
                    moment(data.last_measurement.timestamp).format(
                        'YYYY-MM-DD hh:mm:ss'
                    ),
                    `"${data.start_address}"`,
                    `"${data.end_address}"`,
                    moment(data.last_measurement.timestamp).diff(
                        moment(data.first_measurement.timestamp)
                    ) / 1000,
                    data.last_measurement.trip_distance,
                    data.driver_name,
                    data.driver_id,
                    data.first_measurement.lat,
                    data.first_measurement.lng,
                    data.last_measurement.lat,
                    data.last_measurement.lng,
                ]),
            ]

            const dataUrl = `data:text/csv;charset=utf-8,${rows
                .map(row => row.join(','))
                .join('\n')}`

            const filename = `${this.$t('router.triphistory')}-${new Date()
                .toJSON()
                .slice(0, 19)
                .replace('T', '-')
                .replaceAll(':', '')}.csv`.replace(' ', '-')

            domHelper.downloadDataUrl(dataUrl, filename)
        },
        handleListItemClick({ id }) {
            if (this.isTripMode) {
                this.setSelectedTripID(
                    this.getSelectedTrip?.id === id ? null : id
                )
            } else {
                this.setSelectedHistoryLocationID(id)
            }
        },
        async load() {
            if (this.isTripMode) {
                this.setSelectedTripID(null)
                this.loadTrips({
                    asset_id: this.assetId,
                    from_date: moment(this.dateRange.startDate).format(),
                    to_date: moment(this.dateRange.endDate).format(),
                })
            } else {
                this.isLoadingHistory = true
                await this.loadHistoryData({
                    tracker_id: this.id,
                    from_date: moment(this.dateRange.startDate).format(),
                    to_date: moment(this.dateRange.endDate).format(),
                })
                this.isLoadingHistory = false
            }
        },
        scrollToHistoryItem(id) {
            const className = `historyItem-${id}`
            this.loadListDataUntilFound(className, () => {
                const [element] = this.$el.getElementsByClassName(className)
                if (element && !domHelper.isElementVisible(element)) {
                    element.scrollIntoView()
                }
            })
        },
    },
}
</script>

<i18n>
{
    "en": {
        "showTrips": "Show trips",
        "predefinedFormTitle": "Predefined date range",
        "customFormTitle": "Custom date range",
        "export": "CSV Export",
        "today": "Today",
        "yesterday": "Yesterday",
        "thisMonth": "This month",
        "lastMonth": "Last month",
        "thisYear": "This year",
        "lastYear": "Last year",
        "lastFiveyears": "Last 5 years"
    },
    "de": {
        "showTrips": "Trips anzeigen",
        "predefinedFormTitle": "Vordefinierter Zeitraum",
        "customFormTitle": "Benutzerdefinierter Zeitraum",
        "export": "CSV Export",
        "today": "Heute",
        "yesterday": "Gestern",
        "thisMonth": "Diesen Monat",
        "lastMonth": "Letzer Monat",
        "thisYear": "Dieses Jahr",
        "lastYear": "Letztes Jahr",
        "lastFiveyears": "Letzte 5 Jahre"
    },
    "it": {
        "showTrips": "Visualizzare i viaggi",
        "predefinedFormTitle": "Intervallo di date predefinito",
        "customFormTitle": "Intervallo di date personalizzato",
        "export": "CSV Export",
        "today": "Oggi",
        "yesterday": "Ieri",
        "thisMonth": "Queso Mese",
        "lastMonth": "Lo scorso Mese",
        "thisYear": "Quest' anno",
        "lastYear": "L'anno scorso",
        "lastFiveyears": "Ultimi 5 Anni"
    }
}
</i18n>
